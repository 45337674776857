// Smart Contract
const SMART_CONTRACT_ADDRESS = "0x77C6B2BbC256A1E0FD1013f8f016F9a70Aa6BdaD";
const SMART_CONTRACT_ABI = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "addressPublicSaleMintedBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "addressWhitelistSaleMintedBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "hiddenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32[]",
				"name": "proof",
				"type": "bytes32[]"
			},
			{
				"internalType": "bytes32",
				"name": "leaf",
				"type": "bytes32"
			}
		],
		"name": "isValidWhitelistSaleMerkleTreeProof",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nftPublicSalePerAddressLimit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nftWhitelistSalePerAddressLimit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_mintAmount",
				"type": "uint256"
			}
		],
		"name": "ownerMint",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_mintAmount",
				"type": "uint256"
			}
		],
		"name": "ownerMintForOthers",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSale",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_mintAmount",
				"type": "uint256"
			}
		],
		"name": "publicSaleMint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_uri",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_uri",
				"type": "string"
			}
		],
		"name": "setHiddenURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_supply",
				"type": "uint256"
			}
		],
		"name": "setMaxSupply",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_limit",
				"type": "uint256"
			}
		],
		"name": "setNFTPublicSalePerAddressLimit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_limit",
				"type": "uint256"
			}
		],
		"name": "setNFTWhitelistSalePerAddressLimit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "setPaused",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "setPublicSale",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_cost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "setRevealed",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "setWhitelistSale",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_cost",
				"type": "uint256"
			}
		],
		"name": "setWhitelistSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "_root",
				"type": "bytes32"
			}
		],
		"name": "setWhitelistSaleMerkleRoot",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_owner",
				"type": "address"
			}
		],
		"name": "walletOfOwner",
		"outputs": [
			{
				"internalType": "uint256[]",
				"name": "",
				"type": "uint256[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "whitelistSale",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "whitelistSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "whitelistSaleMerkleTreeRoot",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_mintAmount",
				"type": "uint256"
			},
			{
				"internalType": "bytes32[]",
				"name": "_merkleTreeProof",
				"type": "bytes32[]"
			}
		],
		"name": "whitelistSaleMint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	}
];

// Whitelist sale
const WHITELIST_SALE_PRICE = 0.03;
const WHITELIST_SALE_WHITELIST_ADDRESSES = [
"0x91d48285A2a45cA3f138A4d2474390C675591F47", 
"0x6ab7a0075700b41dC639fD1a1C49885437d401BD",
"0x6b45152d85456009CfA5F0DF58120Aa7c3b59F95",
"0x19b7c2F4d8d8F469C77f6eCeB77B1e5c635931B3",
"0xD2015bc086C29E665f3dbb927c2b6e742cF485EC",
"0xB0ce56786b09071445f99F6898cc9784E324909e",
"0x5e649111465893F03acE7Ebc350f33b1BC7B2DA3",
"0xE070fB24F45acD58415cbBe2381fEa75c03fD0ce",
"0x50e271081BdDb0dd66EC111FD1417691Db14E13f",
"0x8F31f934a9137d112b908a4245Ec16c9F3B47Af5",
"0xB6c6F4e0A1D28C1F21D4f0207FA01eD9f7124A5a",
"0x19CcCCBbE6d6ce25695189F926B48fd3dFeEb117",
"0x1008e36B3a73a4202A3aAB73824BC4f34d283ee1",
"0x7b9f807F23686E8d5f9fD951fEF545576932Fe4A",
"0x565fA474233fc1a4c5A9B402d9dad92A84889A0A",
"0x6b4544955FaDacBc2e058eeC1C343C1cA303C243",
"0x6F27D540fD18B579Fd54b52432C2d33d6e0259Ff",
"0x17f9f48b5b925ce77bfc51c639B0f8dEA899A80b",
"0x4e9a5C09cBa8c2AC85Abe0c21d934f9443Da7D82",
"0x7bCbaC7827293b3FD59Ae49030b64D3F731f8b34",
"0x784151AAe6a4A7c37FC1eD45b3C44b01a829E81E",
"0x871f1C2966389028A47c4F81ad4408D8099Ea709",
"0x0e15AC25a5195DEE8E331166E36f724C2d1c239c",
"0x9Cb921f837807bfa9055eeE0d1a9107995219bdb",
"0xd5ccfE0EFe20E366Aa500252f9a05b5F3E2789c0",
"0x6358bE00c6744e083e91fdA6C0a8df7b848abdCD",
"0x7De7Fb074B79edE5771C90047B79e610e559062c",
"0xde0Fa6299B4CdD054ce4d0C263305E37fB32f749",
"0x6592aBa8AFdB85d2B4C9dA3F99AeA0c2001acFc9",
"0xB08cDc5fb044f9C8889DB515cA1e361f428583b9",
"0x90f83bA585553034Ba5aeC1Ae0Bd305d1C2d6E55",
"0xD2C7317124817a7cF2BAd1343a6753040F5a6dCB",
"0x479838785A79348Cb03aC68C1e2AD8073E045ca5",
"0x8814f13631d20a55Eb51b8425F6Cdc36e023E6cA",
"0x95A353c5581ca8d2BEB6Dc40CeBD8649d51A2970",
"0x9ca8CcE58533F2dfAD0826115f76C9efD14A2Dd9",
"0xdA70bcA35413F3A16183F3c58325B484AdD9BF62",
"0xA328f8B4B0C79fb6e4fdf66D0f3632C6a657B7e9",
"0x64C3d956830a6BdB91b030f7A184623a1b324F95",
"0x27A27B2B458E695667647C26ff9933bB779E0320",
"0xc48c0864b4feee169f0344d6e7dce24f2182513b",
"0x990DfB3bB151559B32d043BF6437c3230C6356a2",
"0x50e271081BdDb0dd66EC111FD1417691Db14E13f",
"0x6e1bc86E2f1c413798925494d8f75Ffd16b45879",
"0xe0aaa554feff3d7ae6d5c6eef7c8fd66d722090d",
"0xc455a3230c7b7eb141d9b6a775ae355ea7abfbf9",
"0x871f1c2966389028a47c4f81ad4408d8099ea709",
"0x93e371f1d71d8f3a163428a9a83dbd4c07540cfb",
"0x4c70c0ce91602db64ab86d522439a68e1a981b23",
"0xba282a20d32248680003dfc1ed8168cbe0b41fa4",
"0x2ae1e669305a1d8821a036d7ec2111a47480669e",
"0xb0ce56786b09071445f99f6898cc9784e324909e",
"0xb7d6e69957ef0cd884f674dcec1789cff7b573b9",
"0x59b63cf66c3eff9a29622e7a91c2f01610997f3a",
"0x81083ee7cac88d4ae3201b16d7d0e35f120c7795",
"0x00a386db00b870b0a508b64db048a339793d0b0d",
"0x437095f014c2c9c3ec964ef8401ef559b3d7ef9b",
"0xff3a62de1da56152aa03e3d41c3349cda89b426b",
"0xc2b841ac0f0aa39a7ffa767381327db8dbbf4527",
"0xc1e3fd1187259870c7eb8b69ed335515cc483442",
"0x8ae2be23f0475c05b3d77aabbfba3e15ae540057",
"0x5643a0e0964efcb774720ffb1aaf691ae3b6f2bf",
"0x990dfb3bb151559b32d043bf6437c3230c6356a2",
"0xd366d69782d7bd9f610e98e3da70171a3d0e2b36",
"0xca84fede1ae4285c140f9b36b4c1aba2077722d6",
"0x543025f669433452143ed5954e040c46f32c7aac",
"0xb1567d410523633311eb5aa7f32b5d6e43ae62db",
"0xce2d67eafeefcb3ee8ad188b88ad735e8859d095",
"0x6bdc53740309acd1e6785093494bfb4bab862694",
"0x6ab7a0075700b41dc639fd1a1c49885437d401bd",
"0x3a8cd4361c56b9aa5c21ab878050312680f8c19c",
"0x51ca6d2cece7d15c8c644fb14744f6f3d0a6425c",
"0xc02de21994ee3bd3c3dd545c9118ca68f8d879f4",
"0x2dee762c610018db46fff63ac7ed15cc7b090472",
"0x1008e36b3a73a4202a3aab73824bc4f34d283ee1",
"0x163c7e136a0dcf7f069bdfd8fa20d31d0ac0ed90",
"0x26871f65b46c6459d02abeb95317611d8f2ed777",
"0x14923c48906734e8e3e376259e3210563f5acf4e",
"0x6b45152d85456009cfa5f0df58120aa7c3b59f95",
"0xc60840848e7168004a6ee9830467c235b90b6626",
"0xf0e168597583a06d52d4a546389be9f6037b5093",
"0x8f31f934a9137d112b908a4245ec16c9f3b47af5",
"0x39ada3b14fde8f516fb278a05ed6456f8c3febf7",
"0x19ccccbbe6d6ce25695189f926b48fd3dfeeb117",
"0xc292e6e81d36e3c8f30920f55e2a182390aa8264",
"0x9bf52869d7d8dbede9a81d528eca00cff2dd9cac",
"0x0d65969832476e7954ba8689fddfda29a5d90dc1",
"0xf63b9d265685cd3db907eadccaab7931247fcb1c",
"0x44f7a6b2719fa09863fb349bd1e4fd29ec1a486c",
"0x4e9a5c09cba8c2ac85abe0c21d934f9443da7d82",
"0x4b0feb0b62ba56f46589ef7de50a02fa8d0b0cc4",
"0x725c6cb5dd50e18a4d1d52ae9e2789ed869a38aa",
"0x99475c75608af9a042e3a05fce60cb14af2606f4",
"0xbf5c9e135eb2d33e3701bbfcc3637ea6a85d3535",
"0xcd67d9bd86a2c2bd90a8887cb719a2e61c2fd317",
"0xd2cdfa27b24032d1d6f0f1098bbda1f7791f4e70",
"0xd15f210d3fa1f5de710b1c613cc51e50fb9a4ab0",
"0x621535391a85fdeadea440b78904f7aceb7bd92a",
"0xa719901788d97b632746e4c221479b2466f5908d",
"0x10527f4c356afa3c12e1187d53f5e08fff67b42d",
"0x48aa59c324d16210f72a5e5faa9d4aeb1af36a3f",
"0x702faccee7ac9336e1a918a007025f45b44d09a8",
"0xb962eb547c19b8af92891e950992bb366aec1420",
"0xf8cc6f2d3b6ee17a4b801758d9323fbce0beeeb5",
"0xf21458edf8c4d5b5d363086949e1b39e58043710",
"0xb179f4e0a98d127a6464465be22c0699870f4a57",
"0xe03eabb3b3dd3da8a902dec786b51f02ebd3cca7",
"0x9c11e1818cde43abe1ee09b8e47aa5496669df5e",
"0xb96f4d2eec07989d1bf25b75ea5decb6673d9642",
"0x71d553b96576a0c7247b638e6cbb868c33e8a3ee",
"0xd4Dc1B9B9766E4423a19F3f021Afcc493C38f534",
"0xA43805E3b2b7c4DF2c54898cd25480eBdeC326B8",
"0xBDd8b54f7aAd803cA51Eb71cd8bCC6a7F25E6Ff6",
"0x2a8690140a5D8Fe4638610c2979E5D837290E6aA",
"0x06db48C8B4f0C0df98412eb12BA199741CC280d8",
"0x849831D28261770F66203F376d99747a7C712087",
"0x230C03C23290B1e45177d783647626B4EA080F59",
"0xA4a33579F732a79b52168121Dd024dFd7b223648",
"0x8CDE3Eb3EC0d56BD8989C83129b224f931acddA8",
"0x3B83f12165CDCb68566c0ED4140a9327f3bb5e39",
"0x3B092B644762cCbB6de54bc44035f66A249433CD",
"0x555BD36180Ab86164510f9a4222c540164826e4A",
"0xc010fa009dc791427595136885e336f4e192bb56",
"0xb9730ccd53c53b920ce59c7785b1df93c3153c2d",
"0x8f05a1728e170b4a2e182e767e2dab2ba7b76ce6",
"0x46dae4decfa6738fb0ed155c7f277ae059e906b0",
"0x6863265950d70Ae4Ea28Cb8eD8D5b7b3aB1EC52f",
"0x73BcF44bb0c64b27dFcC8aCAeA61300718882D03",
"0x91D8A68A470223c17771bC8A7740769699D6934F",
"0xde727003d9d47A47615FE28b0712b503d2216cCc",
"0x791583325d64ea2f74c9c0352fe21f39446a13e7",
"0x287431934b220059ab3c9c255f5972e404ab41ba",
"0xc20bf3849ec94265d86fefde67b12bf2c4134ecd",
"0xbf845ed51bb315348756cddc141fccd9ffc99bd3",
"0xc519a17732ee0e5ef4c58d0c69535825077761d8",
"0x53cbc2fda8347bf269b1d11c94657aadb809099f",
"0x6986be7a4d2000189d5a3b06e39df6c70f42c42a",
"0x8c399e3ff1adea72cf6fd69679761e5801308ff8",
"0x2abe76eb4dfab2e9aca2e9fc09b15b9d7932170a",
"0xDb43A1C2ffBFb83f929D33118daC58FcA3230603",
"0xD5776CA4fe151e1988D74759c348A8bF9c4612a3",
"0xE5A8d394061aA519A45B06C2C6E0A3baBAF0192d",
"0x82A27aA4E3F567502FdBA246342CC7a365CA0394",
"0x8286399fc246d2d580e862aa4aa633a7912945a3",
"0xcdf9b7bbd6c695b96b67644bcce09b6bac024a9b",
"0xa794eb417c0244fa048d8e179549982980cec563",
"0x6de81865eae06a6efe4bbfe2925a7e032eecef03",
"0x222493c8ec159ee8c3d0120cbd4aed2e5b6d7935",
"0x63B7B67cE19D73c27887f969f11C5Ad330ea3265",
"0x7e08CB86fB4A1D8c480EA40178e9E0273e04122a",
"0xA164C3de9DC96Ec78Eca6e381711cc37986789B9",
"0x84f543116A90536F4806E12D5379Ae404D27ec4B",
"0x568194b2cCb1F60e081FC71C1f8f3dCAfd3D90FB",
"0x0f78fDC926cA19Ab3E93e573bAc806434F1199De",
"0x4aE9e62660fceC09f0095769B49C8E3125501105",
"0xb0472cbce335b70ce443630df651b972e4c607da",
"0x5a61347f695a4ae46c89e7188ba50bf2eae3c846",
"0x81d0ca164d927f00e8cb328ed37a44f3bfea6563",
"0x07a3404a0f7f42f59e2d9ab06f29dc556cf90247",
"0xddd899f1a3f889d6201900fa7c7d35f92de5efae",
"0xcf39bf38f08dabb438a1e477f894b744087d837b",
"0x58b7d9ce5973f00fc77fb8876171247305e719a5",
"0xb88d11760561801fbb1508c48ed1c5da85a419f9",
"0xDB47adAABDC849263B4421472E5c199D9Bd52012",
"0x88C2f48A936a32E4f877a43Cd3eE23B5B597167A",
"0xcb2E30DB7944AAd5d14022dC981B52a1BE7F08c9",
"0xA1b96Fa71A098602e00C4F0650AC18081D7De19A",
"0x4869E12c3dB7c0f15dB9B942674ea11D4AC69813",
"0x5fab7c998abe2b3249581a2e1777e02bebd01c18",
"0x3d02a73306407a1111aa3f52eb23b1541ab8142e",
"0x8ef19a326a9c04ce071309eff04d1c7430d57801",
"0x2bFfcF84B9cC919f95dc40fc759353BEaAD2eee1",
"0x07268d7b3e591D2eD547840aEbA16d4B70E77777",
"0x994239cce4570e784d74cbf1b6cb48ad09bac9e9",
"0x21e6acf76878f2d691f13ff291d9218b3f30cab1",
"0x748194c3b65ffc6c8af3a615331f74964c0374ea",
"0x384CfBa9ea5Df300ECC25C34C70366806B3d5C6a",
"0x4b90C43D61e5db7aD20C50fB8c1a13AAB7469F57",
"0xFd3F0B1E335C4fd9953b6ed4b604f0bC754cd865",
"0x03066963febb66acc7cc108b9Ef9F79554233E07",
"0xEFDC2E6E8C23801453738E07043Ba5D98396B289",
"0x248Fae7D18B6223d457e3d9644102De82F81343b",
"0x12f157dDb6221AC5162a3A80Ae32266de0Db50e7",
];

// Public sale
const PUBLIC_SALE_PRICE = 0.05;

// Export
export {
  // Smart Contract
  SMART_CONTRACT_ADDRESS,
  SMART_CONTRACT_ABI,

  // Whitelist sale
  WHITELIST_SALE_PRICE,
  WHITELIST_SALE_WHITELIST_ADDRESSES,

  // Public sale
  PUBLIC_SALE_PRICE,
};
