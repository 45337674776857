import { useState } from "react";

// Dependencies
import { useNavigate, Link } from "react-router-dom";
import { motion } from "framer-motion";

// Navigation bar component
const NavigationBarComponent = ({ walletConnected, connectWallet }) => {
  // Hooks
  const navigate = useNavigate();

  // States
  const [hamburgerMenuOpened, setHamburgerMenuOpened] = useState(false);

  return (
    <nav className="navigation-bar">
      <div className="container">
        <Link to="/" className="logo-wrapper">
          <motion.img
            initial={{ y: "-100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            src="/images/brand/logo.png"
            alt=""
          />
        </Link>

        <motion.button
          initial={{ y: "-100%", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="hamburger-menu-open-trigger"
          onClick={() => {
            setHamburgerMenuOpened(true);
          }}
        >
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.2 8.83333H24.8C25.1182 8.83333 25.4235 8.68408 25.6485 8.4184C25.8736 8.15272 26 7.79239 26 7.41667C26 7.04095 25.8736 6.68061 25.6485 6.41493C25.4235 6.14926 25.1182 6 24.8 6H3.2C2.88174 6 2.57651 6.14926 2.35147 6.41493C2.12643 6.68061 2 7.04095 2 7.41667C2 7.79239 2.12643 8.15272 2.35147 8.4184C2.57651 8.68408 2.88174 8.83333 3.2 8.83333ZM24.8 20.1667H3.2C2.88174 20.1667 2.57651 20.3159 2.35147 20.5816C2.12643 20.8473 2 21.2076 2 21.5833C2 21.9591 2.12643 22.3194 2.35147 22.5851C2.57651 22.8508 2.88174 23 3.2 23H24.8C25.1182 23 25.4235 22.8508 25.6485 22.5851C25.8736 22.3194 26 21.9591 26 21.5833C26 21.2076 25.8736 20.8473 25.6485 20.5816C25.4235 20.3159 25.1182 20.1667 24.8 20.1667ZM24.8 13.0833H3.2C2.88174 13.0833 2.57651 13.2325 2.35147 13.4982C2.12643 13.7639 2 14.1243 2 14.5C2 14.8757 2.12643 15.2361 2.35147 15.5018C2.57651 15.7673 2.88174 15.9167 3.2 15.9167H24.8C25.1182 15.9167 25.4235 15.7673 25.6485 15.5018C25.8736 15.2361 26 14.8757 26 14.5C26 14.1243 25.8736 13.7639 25.6485 13.4982C25.4235 13.2325 25.1182 13.0833 24.8 13.0833Z"
              fill="#FFFFDA"
            ></path>
          </svg>
        </motion.button>

        <div
          className={
            hamburgerMenuOpened
              ? "navigation-bar-elements active"
              : "navigation-bar-elements"
          }
        >
          <button
            className="hamburger-menu-close-trigger"
            onClick={() => {
              setHamburgerMenuOpened(false);
            }}
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="17"
                width="24"
                height="2.35"
                rx="1.175"
                transform="rotate(-45 0 17)"
                fill="white"
              ></rect>
              <rect
                x="2"
                width="24"
                height="2.35"
                rx="1.175"
                transform="rotate(45 2 0)"
                fill="white"
              ></rect>
            </svg>
          </button>

          <motion.ul
            initial={{ y: "-100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <li>
              <Link
                to="/"
                onClick={() => {
                  setTimeout(() => {
                    const location = document.querySelector(".hero").offsetTop;

                    window.scrollTo({
                      left: 0,
                      top: location - 100,
                    });

                    setHamburgerMenuOpened(!hamburgerMenuOpened);
                  }, 300);
                }}
              >
                Home
              </Link>
            </li>

            <li>
              <Link
                to="/"
                onClick={() => {
                  setTimeout(() => {
                    const location = document.querySelector(".about").offsetTop;

                    window.scrollTo({
                      left: 0,
                      top: location - 100,
                    });

                    setHamburgerMenuOpened(!hamburgerMenuOpened);
                  }, 300);
                }}
              >
                About
              </Link>
            </li>

            <li>
              <Link
                to="/"
                onClick={() => {
                  setTimeout(() => {
                    const location =
                      document.querySelector(".mission").offsetTop;

                    window.scrollTo({
                      left: 0,
                      top: location - 100,
                    });

                    setHamburgerMenuOpened(!hamburgerMenuOpened);
                  }, 300);
                }}
              >
                Mission
              </Link>
            </li>

            <li>
              <Link
                to="/"
                onClick={() => {
                  setTimeout(() => {
                    const location = document.querySelector(".team").offsetTop;

                    window.scrollTo({
                      left: 0,
                      top: location - 100,
                    });

                    setHamburgerMenuOpened(!hamburgerMenuOpened);
                  }, 300);
                }}
              >
                Team
              </Link>
            </li>

            <li>
              <Link
                to="/"
                onClick={() => {
                  setTimeout(() => {
                    const location =
                      document.querySelector(".roadmap").offsetTop;

                    window.scrollTo({
                      left: 0,
                      top: location - 100,
                    });

                    setHamburgerMenuOpened(!hamburgerMenuOpened);
                  }, 300);
                }}
              >
                Roadmap
              </Link>
            </li>
          </motion.ul>

          <motion.button
            onClick={() => {
              if (walletConnected) {
                navigate("mint");
              } else {
                connectWallet();
              }
              setHamburgerMenuOpened(!hamburgerMenuOpened);
            }}
            initial={{ y: "-100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="cta-btn"
          >
            {walletConnected ? "Mint" : "Connect Wallet"}
          </motion.button>
        </div>
      </div>
    </nav>
  );
};

// Export
export default NavigationBarComponent;
