import { useEffect, useState } from "react";

// Dependencies
import { Routes, Route } from "react-router-dom";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";

// Components
import NavigationBarComponent from "./components/NavigationBarComponent";
import FooterComponent from "./components/FooterComponent";

// Pages
import LandingPage from "./pages/LandingPage";
import MintPage from "./pages/MintPage";

// Config
import {
  // Smart Contract
  SMART_CONTRACT_ADDRESS,
  SMART_CONTRACT_ABI,

  // Whitelist sale
  WHITELIST_SALE_PRICE,
  WHITELIST_SALE_WHITELIST_ADDRESSES,

  // Public sale
  PUBLIC_SALE_PRICE,
} from "./config";

// App
const App = () => {
  // States
  const [walletConnected, setWalletConnected] = useState(false);
  const [mintQuantity, setMintQuantity] = useState(1);
  const [totalMinted, setTotalMinted] = useState(0);

  // Functions
  //   useEffect(() => {
  //     axios
  //       .get(
  //         `https://api.etherscan.io/api?module=stats&action=tokensupply&contractaddress=${SMART_CONTRACT_ADDRESS}&apikey=4FZDPMBA2BED6JBPHWRJFUDQXYP9AQQ1HM`
  //       )
  //       .then(function (res) {
  //         setTotalMinted(res.data.result);
  //       });
  //   }, []);

  const connectWallet = async () => {
    if (Web3.givenProvider) {
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: "dd04b245d11046998cdabcd91744baf6",
          },
        },
        coinbasewallet: {
          package: CoinbaseWalletSDK,
          options: {
            appName: "Multi Wallet Auth DAPP",
            infuraId: "dd04b245d11046998cdabcd91744baf6",
            chainId: 1,
          },
        },
      };

      const web3Modal = new Web3Modal({
        network: "mainnet",
        cacheProvider: true,
        providerOptions,
      });

      const provider = await web3Modal.connect();
      const web3 = new Web3(provider);

      web3.eth.net.getId();

      const accounts = await web3.eth.getAccounts();
      const account = accounts[0];

      const { ethereum } = window;

      const networkId = await ethereum.request({
        method: "net_version",
      });

      if (networkId === 1 || networkId === `${1}`) {
        setWalletConnected(true);
      } else {
        toast.error(`Please change your network to Ethereum Mainnet`);

        await web3.currentProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(1) }],
        });

        setWalletConnected(true);
      }
    } else {
      window.open(`https://metamask.app.link/dapp/${window.location.href}`);
    }
  };

  const publicSaleMint = async () => {
    if (walletConnected) {
      const web3 = new Web3(Web3.givenProvider);
      await Web3.givenProvider.enable();

      const accounts = await web3.eth.getAccounts();
      const account = accounts[0];

      const contract = new web3.eth.Contract(
        SMART_CONTRACT_ABI,
        SMART_CONTRACT_ADDRESS
      );

      let price = PUBLIC_SALE_PRICE * mintQuantity;
      let bntokens = web3.utils.toWei(price.toString(), "ether");

      contract.methods
        .publicSaleMint(mintQuantity)
        .send({
          gas: "300000",
          from: account,
          value: bntokens,
        })
        .then(() => {
          toast.success("Minted successfully");

          contract.methods
            .totalSupply()
            .call()
            .then((response) => {
              setTotalMinted(response);
            });
        });
    }
  };

  const whitelistSaleMint = async () => {
    if (walletConnected) {
      const web3 = new Web3(Web3.givenProvider);
      await Web3.givenProvider.enable();

      const accounts = await web3.eth.getAccounts();
      const account = accounts[0];

      const contract = new web3.eth.Contract(
        SMART_CONTRACT_ABI,
        SMART_CONTRACT_ADDRESS
      );

      const leaves = WHITELIST_SALE_WHITELIST_ADDRESSES.map((x) =>
        keccak256(x)
      );
      const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
      const buf2hex = (x) => "0x" + x.toString("hex");

      const leaf = keccak256(account);
      const proof = tree.getProof(leaf).map((x) => buf2hex(x.data));

      const root = buf2hex(tree.getRoot());
      console.log(root);
      // console.log(proof);
      // console.log(buf2hex(leaf));

      var index = WHITELIST_SALE_WHITELIST_ADDRESSES.findIndex(function(element) {
        return element.toLowerCase() === account.toLowerCase();
      });

      if (index !== -1) {
        let price = WHITELIST_SALE_PRICE * mintQuantity;
        let bntokens = web3.utils.toWei(price.toString(), "ether");

        contract.methods
          .whitelistSaleMint(mintQuantity, proof)
          .send({
            gas: "300000",
            from: account,
            value: bntokens,
          })
          .then(() => {
            toast.success("Minted successfully");

            contract.methods
              .totalSupply()
              .call()
              .then((response) => {
                setTotalMinted(response);
              });
          });
      } else {
        toast.error("You are not whitelisted");
      }
    }
  };

  return (
    <>
      {/* Toast Container */}
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={1000}
      />

      {/* Navigation bar component */}
      <NavigationBarComponent
        walletConnected={walletConnected}
        connectWallet={connectWallet}
      />

      {/* Routes */}
      <Routes>
        {/* Landing page */}
        <Route path="/" element={<LandingPage />} />

        {/* Mint page */}
        <Route
          path="/mint"
          element={
            <MintPage
              walletConnected={walletConnected}
              connectWallet={connectWallet}
              mintQuantity={mintQuantity}
              setMintQuantity={setMintQuantity}
              totalMinted={totalMinted}
              publicSaleMint={publicSaleMint}
              whitelistSaleMint={whitelistSaleMint}
            />
          }
        />
      </Routes>

      {/* Footer component */}
      <FooterComponent />
    </>
  );
};

// Export
export default App;
