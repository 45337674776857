// Dependencies
import { motion } from "framer-motion";

// Mint page
const MintPage = ({
  walletConnected,
  connectWallet,
  mintQuantity,
  setMintQuantity,
  publicSaleMint,
  whitelistSaleMint,
}) => {
  return (
    <main className="mint-page">
      {/* NFT section */}
      <section className="nft">
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.1 }}
          className="slides"
        >
          <div className="slide">
            <img src="/images/pages/landing-page/nft-img-1.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-2.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-3.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-4.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-5.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-6.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-7.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-8.png" alt="" />
          </div>

          <div className="slide">
            <img src="/images/pages/landing-page/nft-img-9.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-10.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-11.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-12.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-13.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-14.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-15.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-16.png" alt="" />
          </div>

          <div className="slide">
            <img src="/images/pages/landing-page/nft-img-1.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-2.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-3.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-4.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-5.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-6.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-7.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-8.png" alt="" />
          </div>

          <div className="slide">
            <img src="/images/pages/landing-page/nft-img-9.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-10.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-11.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-12.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-13.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-14.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-15.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-16.png" alt="" />
          </div>
        </motion.div>
      </section>

      {/* Mint section */}
      <section className="mint">
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.1 }}
          className="container"
        >
          <div className="mint-box">
            <div className="mint-box-top">
              <div className="mint-box-top-left">
                <img src="/images/pages/mint-page/mint-img-1.png" alt="" />
              </div>

              <div className="mint-box-top-right">
                <div className="quantity-part">
                  <div className="h3-wrapper">
                    <h3 className="text">
                      <span>Quantity</span> <br /> 0{mintQuantity}
                    </h3>
                  </div>

                  <div className="btns">
                    <button
                      onClick={() => {
                        if (mintQuantity > 1) {
                          setMintQuantity(mintQuantity - 1);
                        }
                      }}
                    >
                      <img src="/icons/minus-icon.png" alt="" />
                    </button>

                    <button
                      onClick={() => {
                        if (mintQuantity < 6) {
                          setMintQuantity(mintQuantity + 1);
                        }
                      }}
                    >
                      <img src="/icons/plus-icon.png" alt="" />
                    </button>
                  </div>
                </div>

                <div className="price-part">
                  <div className="h3-wrapper">
                    <h3 className="text">
                      <span>Mint Price</span> <br /> 0.03 ETH
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <button
              onClick={() => {
                if (walletConnected) {
                  // On whitelist sale uncomment it
                  whitelistSaleMint();

                  // On public sale uncomment it
                  // publicSaleMint();
                } else {
                  connectWallet();
                }
              }}
              className="cta-btn"
            >
              {walletConnected ? "Mint Now" : "Connect Wallet"}
            </button>

            {/* <p className="address">0x3ae48....fd05</p> */}
          </div>
        </motion.div>
      </section>

      {/* NFT section */}
      <section className="nft nft-2">
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.1 }}
          className="slides"
        >
          <div className="slide">
            <img src="/images/pages/landing-page/nft-img-17.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-18.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-19.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-20.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-21.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-22.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-23.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-24.png" alt="" />
          </div>

          <div className="slide">
            <img src="/images/pages/landing-page/nft-img-25.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-26.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-27.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-28.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-29.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-30.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-31.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-32.png" alt="" />
          </div>

          <div className="slide">
            <img src="/images/pages/landing-page/nft-img-17.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-18.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-19.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-20.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-21.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-22.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-23.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-24.png" alt="" />
          </div>

          <div className="slide">
            <img src="/images/pages/landing-page/nft-img-25.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-26.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-27.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-28.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-29.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-30.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-31.png" alt="" />
            <img src="/images/pages/landing-page/nft-img-32.png" alt="" />
          </div>
        </motion.div>
      </section>
    </main>
  );
};

// Export
export default MintPage;
